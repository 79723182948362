/* globals document window */

// We use ES2015+ JS features across this project.
// We use babel-polyfill to replicate these features in legacy browsers.
// Array.includes() is one of the main features.
// https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#supported-language-features-and-polyfills
import 'babel-polyfill';
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import Raven from 'raven-js';

import Routes from './routes';
import zippity from './zippity';
import { apiGet, getProNameHeader, redirectUrl } from './brainApi';
import { setZ3PConfig } from './helper';
import * as FullStory from '@fullstory/browser';
import * as rudderAnalytics from 'rudder-sdk-js';

if (process.env.REACT_APP_ENVIRO === 'production') {
  Raven.config(
    'https://ee5cd11cbbeb48c29461a866ce60f9e6@sentry.io/251612',
  ).install();
  if (
    process.env.REACT_APP_RUDDER_STACK_PROD_WRITE_KEY &&
    process.env.REACT_APP_RUDDER_STACK_DATA_PLANE_URL
  ) {
    rudderAnalytics.load(
      process.env.REACT_APP_RUDDER_STACK_PROD_WRITE_KEY,
      process.env.REACT_APP_RUDDER_STACK_DATA_PLANE_URL,
    );
  }
}

if (
  process.env.REACT_APP_ENVIRO === 'dev' ||
  process.env.REACT_APP_ENVIRO === 'staging' ||
  process.env.REACT_APP_ENVIRO === 'test'
) {
  if (
    process.env.REACT_APP_RUDDER_STACK_STAGE_WRITE_KEY &&
    process.env.REACT_APP_RUDDER_STACK_DATA_PLANE_URL) {
    rudderAnalytics.load(
      process.env.REACT_APP_RUDDER_STACK_STAGE_WRITE_KEY,
      process.env.REACT_APP_RUDDER_STACK_DATA_PLANE_URL,
    );
  }
}

FullStory.init({ orgId: 'Q1WHG' });
// Safety check for Internet Explorer to handle console.log()
// https://stackoverflow.com/questions/3326650/console-is-undefined-error-for-internet-explorer/3326695
(function fixWindowConsole() {
  // eslint-disable-next-line no-multi-assign
  const console = (window.console = window.console || {});
  const noop = () => {};
  if (!console.log) {
    console.log = noop;
  }
})();

redirectUrl();

// application entry file
async function init() {
  const store = await zippity();
  await apiGet('/app-z3p-configuration')
    .then((response) => response.json())
    .then((z3pConfiguration) => {
      setZ3PConfig(z3pConfiguration);
      store.dispatch({ type: 'SET_Z3P_CONFIGURATION', z3pConfiguration });

      const {
        customer_facing_name,
        customer_facing_email,
        customer_facing_address,
        google_tag_manager_container_id,
        google_analytics_tracking_id,
        call_forwarding_phone_raw
      } = z3pConfiguration;
      const title = document.getElementById('replace-title');
      const email = document.getElementById('support-email');
      const phone = document.getElementById('support-phone');
      const address = document.getElementById('support-address');
      const addressBlock = document.getElementById('support-address-block');

      if (title && customer_facing_name) {
        title.innerText = customer_facing_name;
        title.hidden = false;
      }
      if (email && customer_facing_email) {
        email.innerText = customer_facing_email;
        email.href = `mailto:${customer_facing_email}`;
        email.hidden = false;
      }
      if (phone && call_forwarding_phone_raw) {
        phone.innerText = call_forwarding_phone_raw;
        phone.href = `tel:${call_forwarding_phone_raw}`;
        phone.hidden = false;
      }
      if (address && customer_facing_address) {
        address.innerText = customer_facing_address;
        address.href = `javascript:;`;
        address.hidden = false;
      } else {
        addressBlock.style.display = 'none';
      }
      if (google_tag_manager_container_id) {
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          const f = d.getElementsByTagName(s)[0];
          const j = d.createElement(s);
          const dl = l != 'dataLayer' ? `&l=${l}` : '';
          j.async = true;
          j.src = j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
          f.parentNode.insertBefore(j, f);
        })(
          window,
          document,
          'script',
          'dataLayer',
          google_tag_manager_container_id,
        );
      }
      if (google_analytics_tracking_id && window?.gtag) {
        window.gtag('config', google_analytics_tracking_id);
      }
    });

  const proName = getProNameHeader();
  apiGet(`/pro/${proName}`)
    .then((response) => response.json())
    .then((proConfiguration) => {
      store.dispatch({ type: 'SET_PRO_CONFIGURATION', proConfiguration });
    });

  apiGet('/coupon')
    .then((response) => response.json())
    .then((couponList) => {
      store.dispatch({ type: 'SET_COUPON_LIST', couponList });
    });

  // stuff to do after rehydration
  // loadSchedule(store.dispatch);

  ReactDOM.render(
    <Provider store={store}>
      <Routes history={browserHistory} />
    </Provider>,
    document.getElementById('react'),
  );
}

init();
