import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Col, Row, Card, Icon } from 'react-materialize';

import InputMask from 'react-input-mask';
import Pricing from '../components/Pricing';
import { hasAccessToken } from '../../brainApi';
import { setPageTitle } from '../../helper';

class TireSizeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = { showTireSizeInput: false };
  }

  componentWillMount() {
    setPageTitle('Select Tire Size');
  }

  handleTireSizeSelection(tireSize) {
    if (tireSize === 'Other') {
      this.setState({ showTireSizeInput: true });
      return null;
    }
    const sanitizedTireSize = tireSize.replace(/[^0-9]/gi, '');
    const { clientLocationId, dispatch, homeAddress } = this.props;
    dispatch({
      type: 'SET_TIRE_SIZE',
      tireSize: sanitizedTireSize,
      rawTireSize: tireSize,
    });
    if (
      hasAccessToken() &&
      !((homeAddress && homeAddress.addressIDSelected) || clientLocationId)
    ) {
      return browserHistory.push('/pricing/place');
    }
    return browserHistory.push('/pricing/schedule');
  }

  renderTireSizeBadge = (
    previouslySelectedTireSize,
    technicianVerifiedTireSize,
  ) => {
    const doBothTireSizeValuesExist =
      !!previouslySelectedTireSize && !!technicianVerifiedTireSize;
    const doTireSizesMatch =
      previouslySelectedTireSize === technicianVerifiedTireSize;

    if (doTireSizesMatch || !doBothTireSizeValuesExist)
      return (
        <div className="tire-size-badge z-depth-2">
          <span className="tooltip z-depth-2">
            {previouslySelectedTireSize
              ? "You've selected this tire size before"
              : "We've seen this tire size on your vehicle"}
          </span>
        </div>
      );
    return null;
  };

  render() {
    const { pricing, z3pConfiguration } = this.props;
    const {
      z3p_client_name: z3pEnv,
      customer_facing_phone: phone,
    } = z3pConfiguration;
    const {
      standard_tire_sizes: standard,
      pt_front_tire_size: front,
      pt_rear_tire_size: rear,
      selected_tire_size: previouslySelectedTireSize,
      technician_verified_tire_size: technicianVerifiedTireSize,
    } = pricing;

    const tireSizeSet = new Set(standard);
    if (front) {
      tireSizeSet.add(front);
    }
    if (rear) {
      tireSizeSet.add(rear);
    }

    // We only want to display a customer's previously selected
    // if the following conditions are true: Both sizes exist and are equal OR only the previously selected tire size exists
    const doTireSizesMatch =
      previouslySelectedTireSize === technicianVerifiedTireSize;
    if (
      !!previouslySelectedTireSize &&
      (doTireSizesMatch || !technicianVerifiedTireSize)
    ) {
      tireSizeSet.add(previouslySelectedTireSize);
    }

    const { showTireSizeInput, showTireSizeHelp } = this.state;

    const allTireSizes = [...tireSizeSet];

    return (
      <Pricing
        currentStep="Services"
        h1="Select Your Services"
        showMotorAttribution
      >
        <h4>Choose Your Tire Size</h4>

        {!!allTireSizes.length && (
          <p>
            Many makes have multiple tire size options, select the one for your
            specific vehicle
          </p>
        )}
        <Row>
          <Col className="tire-sizes" l={6} m={8} s={12}>
            {allTireSizes.map((size) => (
              <Row key={size} l={6} m={8} s={12}>
                <Card onClick={() => this.handleTireSizeSelection(size)}>
                  <strong>{`${size.slice(size.length - 2)}" | `}</strong>
                  {size}
                  {(size === previouslySelectedTireSize ||
                    size === technicianVerifiedTireSize) &&
                    this.renderTireSizeBadge(
                      previouslySelectedTireSize,
                      technicianVerifiedTireSize,
                    )}
                </Card>
              </Row>
            ))}
            {!!allTireSizes.length && !showTireSizeInput && (
              <Row key="Other">
                <Card
                  onClick={() => this.setState({ showTireSizeInput: true })}
                >
                  Other
                </Card>
              </Row>
            )}
            {(showTireSizeInput || !allTireSizes.length) && (
              <form
                className="row"
                onSubmit={() =>
                  this.handleTireSizeSelection(this.tireSize.value)
                }
              >
                <Col m={6}>
                  <div className="input-field">
                    <InputMask
                      ref={(i) => (this.tireSize = i)}
                      mask="P999/99R99"
                      placeholder="P___/__R__"
                      id="tireSize"
                      name="tireSize"
                      type="text"
                      required="required"
                      pattern={'P\\d{3}/\\d{2}R\\d{2}'}
                    />
                    <label className="active" htmlFor="tireSize">
                      Tire Size
                    </label>
                  </div>
                </Col>
                <Col m={6}>
                  <button
                    type="submit"
                    name="action"
                    value="Submit"
                    className="btn-large white black-text"
                  >
                    Continue
                  </button>
                </Col>
              </form>
            )}
          </Col>
        </Row>
        <br />

        <p
          style={{
            textDecoration: 'underline',
            fontSize: '.9rem',
            cursor: 'pointer',
          }}
          onClick={() => this.setState({ showTireSizeHelp: !showTireSizeHelp })}
        >
          <Icon left>help</Icon>Need help finding your tire size?
        </p>
        {showTireSizeHelp && (
          <Row>
            <Col s={12} m={4}>
              <p>1) Owner&apos;s Manual</p>
              <img
                src="https://zippity.imgix.net/booking-app/prod-1559171644891-owners-manual.png"
                alt="Owners Manual"
                style={{ maxWidth: '100%' }}
              />
            </Col>
            <Col s={12} m={4}>
              <p>2) Door Jamb</p>
              <img
                src="https://zippity.imgix.net/booking-app/prod-1559171602600-door-jamb.jpg"
                alt="Door Jamb"
                style={{ maxWidth: '100%' }}
              />
            </Col>
            <Col s={12} m={4}>
              <p>3) On the tire itself</p>
              <img
                src="https://zippity.imgix.net/booking-app/prod-1559171579530-tire-size.png"
                alt="Tire Size"
                style={{ maxWidth: '100%' }}
              />
            </Col>
          </Row>
        )}
        <p>
          If you have questions about how to find your tire size, please
          {z3pEnv === 'zippity'
            ? ' use our Live Chat in the bottom-right, or'
            : ''}{' '}
          call us at {phone}.
        </p>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    priceList: state.ui.priceList,
    cart: state.ui.cart,
    tireDetails: state.ui.tireDetails,
    pricing: state.ui.pricing,
    customerLocation: state.ui.customerLocation,
    tireProducts: state.ui.tireProducts,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(TireSizeSelection);
