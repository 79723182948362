import React, { useEffect } from 'react';

const Feedbackify = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//cdn.feedbackify.com/f.js';

    // eslint-disable-next-line no-undef
    document.body.appendChild(script);

    return () => {
      // eslint-disable-next-line no-undef
      document.body.removeChild(script); // Clean up on unmount
    };
  }, []);

  const handleFeedbackClick = () => {
    // eslint-disable-next-line no-undef
    if (window.fby) {
      // eslint-disable-next-line no-undef
      window.fby.push(['showForm', '16870']); // Replace with your Feedbackify ID
    }
  };

  return (
    // eslint-disable-next-line react/button-has-type
    <button onClick={handleFeedbackClick} className="feedback-button">
      feedback
    </button>
  );
};

export default Feedbackify;
