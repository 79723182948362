// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { tracRudderStackkEvent } from '../helper';

const ReviewPage = (props) => {
  const { z3pConfiguration } = props;
  const {
    google_review_url,
    z3p_client_name,
    customer_facing_name,
  } = z3pConfiguration;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size > 0) {
      const utm_source = queryParams.get('utm_source');
      const utm_campaign = queryParams.get('utm_campaign');
      tracRudderStackkEvent('Review_Pages', {
        proname: z3p_client_name,
        // eslint-disable-next-line no-undef
        path: window.location.pathname,
        // eslint-disable-next-line no-undef
        url: window.location.href,
        // eslint-disable-next-line no-undef
        title: document.title ? document.title : customer_facing_name,
        // eslint-disable-next-line no-undef
        referrer: document.referrer,
        utm_source,
        utm_campaign,
      });
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.location.href = google_review_url;
    }, 2000);
  }, []);

  return null;
};
function mapStateToProps(state) {
  return {
    account: state.ui.account,
    vehicles: state.ui.vehicles,
    vehicle: state.ui.pricing,
    cart: state.ui.cart,
    clientLocations: state.ui.clientLocations,
    z3pConfiguration: state.ui.z3pConfiguration,
    homeAddress: state.ui.homeAddress,
  };
}

export default connect(mapStateToProps, null)(ReviewPage);
