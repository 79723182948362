import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Icon, Modal, Checkbox } from 'react-materialize';

import { getServiceById } from '../serviceHelpers';
import { formatPrice } from '../helper';

const moreInfoModal = (serviceOffering) => {
  const {
    service_offering_id,
    short_name,
    card_bullet_points,
    more_info_image,
    details_blurb,
  } = serviceOffering;
  const hasBulletPoints = !!card_bullet_points && card_bullet_points.length > 0;

  return (
    <Modal
      id={`modal${service_offering_id}`}
      header={short_name}
      trigger={
        (hasBulletPoints || more_info_image || details_blurb) && (
          <div className="more-info-modal" waves="light">
            <Icon small>info_i</Icon>
          </div>
        )
      }
    >
      {hasBulletPoints && (
        <div>
          <ul className="detailing-modal-list">
            {card_bullet_points.map((bulletPoint) => (
              <li key={bulletPoint}>
                <Icon className="tiny">fiber_manual_record</Icon> {bulletPoint}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!details_blurb && (
        <div>
          <p>{details_blurb}</p>
        </div>
      )}
      {!!more_info_image && (
        <img
          src={`${more_info_image}?auto=format,compress&fit=max&w=500`}
          alt={short_name}
        />
      )}
    </Modal>
  );
};

function TireServiceCard(props) {
  const {
    serviceOffering,
    handleSelectTireService,
    cart,
    includedServices,
    isServiceClickEvent,
  } = props;
  const {
    card_image_url,
    is_atd_tire_product,
    is_tire_installation_fee,
    labor_cost_dollars,
    long_name,
    per_tire,
    service_offering_id,
    short_name,
    subtitle,
    total_cost_dollars,
    variable_pricing,
  } = serviceOffering;

  // Note: We have two special tire service flags: is_atd_tire_product and is_tire_installation_fee.
  //  * The "ATD Tire Product" is used as a placeholder service, but is not shown
  //    in the booking flow. (It should have bookable = false.)
  //  * The "Tire Installation" is the service the customer can book from this
  //    page. (It should have bookable = true.)
  // When the customer selects a "Tire Installation" service, it will direct
  // them to the "tire size" page, and then to the ATD product selection page.
  // And then when they choose a tire, it will find the "ATD Tire Product" and
  // add the right quantity of both to the cart.
  // TODO: It would be great to simplify all of this and just use one flag.

  const placeholder_card_image_url =
    'https://zippity.imgix.net/booking-app/prod-1621605552240-Exterior%20clean%202.png';

  let isSelected = !!getServiceById(cart, service_offering_id);
  if (
    cart.length === 0 &&
    !isServiceClickEvent &&
    includedServices.length !== 0
  ) {
    // eslint-disable-next-line react/destructuring-assignment
    props.dispatch({ type: 'SET_CART', cart: includedServices });
    isSelected = !!getServiceById(includedServices, service_offering_id);
  }
  const imageStyling = {
    maxHeight: '100%',
    maxWidth: '100%',
  };
  return (
    <Col s={12} m={6} l={4}>
      <Card
        className={`tire-card ${isSelected ? 'tire-card-selected' : ''}`}
        textClassName="tire-card-text"
        onKeyPress={() => handleSelectTireService(serviceOffering)}
        onClick={() => handleSelectTireService(serviceOffering)}
      >
        <div className="serviceBox">
          <div
            className={`service-img ${isSelected ? 'tire-card-selected' : ''}`}
          >
            {card_image_url && (
              <img
                alt="Card"
                style={imageStyling}
                src={`${card_image_url ||
                  placeholder_card_image_url}?auto=format,compress&fit=max&w=500`}
                title={short_name}
              />
            )}
            {!card_image_url && long_name !== 'Request a service' && (
              <img
                alt="Tire Service Offering Icon"
                src="/static/img/tires/tire-service-offering.png"
              />
            )}
            {!card_image_url && long_name === 'Request a service' && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_400_1428)">
                  <path
                    d="M7.01967 14.8163C4.98193 16.7755 3.0574 18.6122 2.26495 19.3469C1.92533 19.5918 1.69891 19.9591 1.4725 20.4489C1.13288 21.1836 1.01967 21.7959 1.4725 22.4081C2.26495 23.6326 3.73665 23.6326 4.64231 22.653L9.62344 17.5102"
                    stroke="#181818"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.2837 11.3877C16.7554 9.67342 17.8875 8.44893 18.1139 8.20403C18.1139 8.20403 18.1139 8.20403 18.2271 8.20403C18.7931 8.20403 24.7931 8.57138 23.2082 3.18362C23.2082 3.06117 23.095 3.06117 23.095 3.18362L22.9818 3.55097C22.4158 4.65301 21.6233 5.3877 20.4912 5.75505C20.378 5.75505 20.2648 5.75505 20.1516 5.75505C19.9252 5.75505 19.6988 5.6326 19.4724 5.51015L19.2459 5.26526C18.6799 4.77546 18.5667 3.91832 18.9063 3.18362C19.0195 2.44893 19.5856 1.71423 20.2648 1.22444L20.8309 0.857092V0.734643C15.2837 -0.489846 16.1893 5.51015 16.1893 5.51015L13.0195 9.30607"
                    stroke="#181818"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.9434 20.9388L18.3397 23.3878L9.05664 13.7143L11.8868 10.7755L20.9434 20.9388Z"
                    stroke="#181818"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.96263 12.8571L2.60414 5.14284L1.47207 4.89794L0.566406 2.93876L2.0381 1.34692L3.73622 2.32652L4.07584 3.91835L11.0947 11.5102L9.96263 12.8571Z"
                    stroke="#181818"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.1133 14.0817L16.868 19.347"
                    stroke="#181818"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_400_1428">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
          <div className="service-content">
            <div className="d-flex">
              <h3>{short_name}</h3>
              {moreInfoModal(serviceOffering)}
            </div>
            <p className="description">{subtitle}</p>
          </div>
          <div className="price-box">
            <div className="service_checkbox">
              <Checkbox
                className="filled-in"
                id={service_offering_id}
                checked={isSelected}
                onChange={() => handleSelectTireService(!serviceOffering)}
              />
            </div>
            <h5 className="tire-card-price">
              {is_tire_installation_fee || is_atd_tire_product ? (
                <Fragment>
                  Pricing on next page
                  <br />
                  {labor_cost_dollars > 0 && (
                    <small>
                      +{formatPrice(labor_cost_dollars)}{' '}
                      {per_tire && 'per tire'} for installation
                    </small>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <small>{variable_pricing && 'Starting at '}</small>
                  {formatPrice(total_cost_dollars)}
                  <small> {per_tire && 'per tire'}</small>
                </Fragment>
              )}
            </h5>
          </div>
        </div>
      </Card>
    </Col>
  );
}

function mapStateToProps(state) {
  return {
    cart: state.ui.cart,
  };
}

export default connect(mapStateToProps, null)(TireServiceCard);
